import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView } from '@wix/bi-logger-membership/v2';
import { IntegrationData } from '@wix/pricing-plans-router-utils';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../../constants';
import { OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import { WarmupData } from '../../../services/WarmupData';
import { ListProps, CommonProps, PopupEnum, ModalType, noModal } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { WIDGET_TYPE } from '../../../utils/bi';
import { toError } from '../../../utils/errors';
import { getOrderedPlans, getOrderedVisiblePlans } from '../../../utils/plan-visibility';
import { SettingsReader } from '../DefaultSettingsAdapter';
import { Router } from './Router';

export class ListController {
  constructor(
    protected setProps: (props: Partial<CommonProps & ListProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected premiumApi: PremiumApi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
    protected warmupData: WarmupData,
    protected readonly hasMultiplePages: boolean,
  ) {}

  async initialize(integrationData: IntegrationData) {
    return this.update(integrationData);
  }

  async update(integrationData: IntegrationData, publicPlans?: PublicPlan[]) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.ListPageLoaded);

    const { plans } = publicPlans
      ? { plans: this.orderPlans(publicPlans, integrationData) }
      : await this.warmupData.cache('list.plans', () => this.fetchAndOrderPlans(integrationData));

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          referralId: integrationData.biOptions?.referralId,
          referral_info: integrationData.biOptions?.referralInfo,
          widgetType: WIDGET_TYPE.page,
        }),
      );
    }
    this.analytics.addProductImpression(plans);

    this.setProps({
      overrideAppTitle: integrationData.title,
      overrideAppSubtitle: integrationData.subtitle,
      plans,
      popup: null,
      hidePopup: this.hidePopup,
      selectPlan: (plan) => this.selectPlan(plan, integrationData),
      navigateToHomePage: () => this.router.gotoHomePage(),
    });
  }

  fetchAndOrderPlans = async (integrationData?: IntegrationData) => {
    const plans = await this.plansApi
      .loadPaidPlans(integrationData?.planIds ? { planIds: integrationData?.planIds } : undefined)
      .catch((e) => {
        this.flowAPI.errorMonitor.captureException(toError(e));
        return [] as PublicPlan[];
      });
    return { plans: this.orderPlans(plans, integrationData) };
  };

  orderPlans = (plans: PublicPlan[], integrationData: IntegrationData | undefined): PublicPlan[] => {
    const useFixture = !plans.length && this.wixCodeApi.window.viewMode === 'Editor';
    return useFixture
      ? this.demoPlans
      : integrationData?.planIds
      ? getOrderedPlans(plans, this.settings)
      : getOrderedVisiblePlans(plans, this.settings);
  };

  protected selectPlan = async (plan: PublicPlan, integrationData: IntegrationData) => {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.PlanSelected);
    const planId = plan.id;

    if (!planId) {
      this.flowAPI.reportError('Plan has no id.');
      return;
    }

    this.setProps({ selectedPlanId: planId });
    if (this.wixCodeApi.window.viewMode !== 'Site') {
      if (this.isAlertModalsEnabled()) {
        this.setProps({
          modal: { type: ModalType.PurchaseInPreview, onClose: this.closeModal },
          selectedPlanId: undefined,
        });
      } else {
        this.setProps({ popup: PopupEnum.checkoutPreview, selectedPlanId: undefined });
      }

      this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
      return;
    }

    this.flowAPI.bi?.report(planPurchaseClick({ planGuid: plan.id!, widgetType: 'page' }));
    this.analytics.clickProduct(plan);

    this.router.gotoCheckout(plan, integrationData);

    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
  };

  private isAlertModalsEnabled() {
    return this.flowAPI.experiments.enabled(EXPERIMENTS.ALERT_MODALS);
  }

  protected hidePopup = () => this.setProps({ popup: null });

  private closeModal = () => this.setProps({ modal: noModal });
}
